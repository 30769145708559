import { graphql, PageProps } from "gatsby"
import * as React from "react"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../templates/layout"
import Seo from "../templates/seo"
import BlogPostList from "../components/organisms/blog-post-list"
import Sidebar from "../components/organisms/sidebar"
import Card from "../components/atoms/card"
import {
  l_page_container,
  l_page_content
} from "../components/atoms/__layout"

const Allpage: React.FC<PageProps<Queries.AllpageQuery>> = ({ data }) => {
  const postdata = data?.allMarkdownRemark?.nodes
  // const tagdata = data?.allMarkdownRemark?.nodes.frontmatter?.tags
  const name = data?.site?.siteMetadata?.author?.name
  const description = data?.site?.siteMetadata?.description
  const breakpoints = useBreakpoint()
  return (
    <Layout>
      <Seo title="All page" />
      <div css={l_page_container}>
        <section css={l_page_content}>
          <BlogPostList postsdata={postdata} />
        </section>
        {breakpoints.pc ? <Sidebar toc={false} author={name} description={description} /> : <div />}
      </div>
    </Layout >
  )
}

export default Allpage

export const pageQuery = graphql`
  query Allpage {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { slug: { regex: "//blog/" } } }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "YYYY.MM.DD")
          title
          description
          tags
        }
      }
    }
  }
`
