import React from "react"
import { css } from "@emotion/react"

import Listcard from "../molecules/list_card"

const BlogPostList = ({ postsdata }: Postdata) => {
  return (
    <div>
      <ul css={index_list}>
        {postsdata?.map(item => (
          <li key={item.url}>
            <Listcard
              title={item.frontmatter.title}
              date={item.frontmatter.date}
              url={item.fields.slug}
              tags={item.frontmatter.tags}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default BlogPostList

const index_list = css`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;
  margin: 0;
  h2 {
    margin: 10px 0;
  }
`
const o_postlist_title = css`
  margin: 0;
  text-align: center;
  padding: 10px;
`
